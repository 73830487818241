// Here you can add other styles

.rdw-editor-wrapper {
  box-sizing: inherit !important;
}

.light-background {
  background-color: #e2e5e7;
}

html {
  font-size: 15px;
}
